<template>
  <v-list-item
    two-line
    class="d-inline-flex"
  >
    <v-list-item-content>
      <v-list-item-subtitle>{{ label }}</v-list-item-subtitle>
      <v-list-item-title v-if="url">
        <a
          :href="url"
          target="_blank"
        >{{ value }}</a>
      </v-list-item-title><v-list-item-title v-else>
        {{ value }}
      </v-list-item-title>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
export default {
  name: 'LabelValue',

  props: {
    label: { type: [String, Number], default: null },
    value: { type: [String, Number], default: null },
    url: { type: String, default: null }
  }
}
</script>
