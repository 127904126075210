var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-list-item",
    { staticClass: "d-inline-flex", attrs: { "two-line": "" } },
    [
      _c(
        "v-list-item-content",
        [
          _c("v-list-item-subtitle", [_vm._v(_vm._s(_vm.label))]),
          _vm.url
            ? _c("v-list-item-title", [
                _c("a", { attrs: { href: _vm.url, target: "_blank" } }, [
                  _vm._v(_vm._s(_vm.value))
                ])
              ])
            : _c("v-list-item-title", [
                _vm._v("\n      " + _vm._s(_vm.value) + "\n    ")
              ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }